.top,
.bottom {
  display: flex;
}

.products-card {
  position: relative;
  min-height: 22.925rem;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 8px;
  outline: 1px solid #eee;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 5%);
  transition: outline 0.2s;
  height: 100%;
}

.products-card:hover {
  outline: 1px solid #ea8242;
}

.products-border-less {
  cursor: auto;
}

.products-border-less:hover {
  outline: 1px solid rgb(221 221 221 / 50%);
}

.products-border-unavailable:hover {
  outline: 1px solid #ccc;
}

.bottom {
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
}

.subtitle {
  color: #254e5f;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.desc {
  width: 66%;
  margin-left: 1.5rem;
  text-align: left;
}

.title {
  max-height: 3.5rem;
  overflow: hidden;
  color: #254e5f;
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 800;
}

.mt {
  margin-top: 1.6rem;
}

.honest-sign {
  position: absolute;
  top: -30px;
  left: 0;
  z-index: 1;
}

.pseudo-link {
  color: #ea8242;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  top: 0;
}

.pseudo-link:hover {
  color: #0a58ca;
}

.product-counter {
  flex: 1;
  margin-left: 1.5rem;
}

.text,
.product-amount-price span {
  color: #333;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: Roboto, "Segoe UI", serif;
  position: relative;
}

.text p {
  margin-top: 1rem;
}

.unavailable {
  opacity: 0.5;
}

.unavailable-text {
  max-width: 18rem;
  margin: 1rem 1.2rem 0 auto;
  color: #adadb8;
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: end;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
}

.hidden {
  display: none;
}

.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.delivery-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.75rem;
}

.delivery-desc {
  font-family: Roboto, "Segoe UI", serif;
  font-size: 0.75rem;
  margin-left: 0.5rem;
  color: #333;
}

.delivery-img {
  display: flex;
  align-items: center;
  height: 1rem;
}

.server-html {
  position: relative;
  height: 8.3rem;
  overflow: hidden;
}

.gradient {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 22.125rem;
  height: 1.2rem;
  background:
    linear-gradient(
      90deg,
      rgb(255 255 255 / 0%),
      rgb(255 255 255 / 100%)
    );
}

.hidden-block {
  position: absolute;
  width: 0.6rem;
  height: 0.3rem;
  right: 6.5rem;
  bottom: 0;
  background: #fff;
  z-index: 10;
}

.hidden-block-in-card {
  position: absolute;
  width: 0.6rem;
  height: 0.3rem;
  left: 17.7rem;
  top: 8rem;
  background: #fff;
  z-index: 10;
}

@media (max-width: 1024px) {
  .top {
    align-items: center;
    flex-direction: column;
  }

  .bottom {
    margin-top: 1.5rem;
    justify-content: center;
  }

  .desc {
    margin-left: 0;
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .bottom {
    flex-direction: column;
  }

  .product-counter {
    margin-left: 0;
  }

  .products-card {
    width: 100%;
  }
}
