.products-page {
  padding: 3.125rem 0;
}

.title {
  font-size: 22px;
}

.products-cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.714rem;
  justify-content: space-between;
}

.products-card {
  position: relative;
  width: calc(50% - 16px);
  margin-bottom: 32px;
}

.products-img {
  max-width: 144px;
  height: auto;
  object-fit: cover;
}

.scroll-btn {
  position: fixed;
  bottom: 17rem;
  right: 25rem;
  padding: 0.875rem;
  margin-left: 2rem;
  border: 1px solid #ea8242;
  border-radius: 2px;
  font-size: 1.187rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  color: #fff;
  background: #f6e2d5;
}

.arrow-up {
  width: 1.25rem;
  height: 1.25rem;
}

@media (max-width: 768px) {
  .products-card {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .scroll-btn {
    right: 15rem;
  }
}

@media (max-width: 991px) {
  .scroll-btn {
    right: 1rem;
    bottom: 31.5rem;
  }
}
