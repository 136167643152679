.app-header {
  flex: 0;
  min-height: 88px;
}

.back {
  background: #f8f8f8;
  box-shadow: inset 0 -2px 4px rgb(0 0 0 / 4%);
}

.nav-link,
.nav-link:hover {
  padding: 14px 32px;
  color: #ea8242;
  text-decoration: none;
}

.active {
  background: #fff;
  border: 1px solid #ea8242;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
}

.profile-block {
  position: relative;
  padding-right: 1rem;
}

@media (max-width: 1199px) {
  .nav-link,
  .nav-link:hover {
    padding: 14px 20px;
  }

  .profile-block {
    width: 19.375rem;
  }
}

@media (max-width: 515px) {
  .profile-block {
    width: calc(100% - 10.3125rem);
  }

  .app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    background: #f8f8f8;
    box-shadow: inset 0 -2px 4px rgb(0 0 0 / 4%);
  }
}
