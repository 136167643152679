.info-banner {
  width: 752px;
  display: flex;
  column-gap: 16px;
  margin-top: 24px;
  padding: 16px;
  background: #f3fafc;
  border: 1px solid #5bc0de;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 10px -1px rgb(0 0 0 / 10%);
}

.in-menu-info-banner {
  width: 466px;
}

.info-banner-desc {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 14px;
  line-height: 18px;
  color: #333;
}

@media (max-width: 820px) {
  .info-banner {
    width: 100%;
  }
}
