.not-found {
  text-align: center;
  max-width: 1063px;
  margin: 0 auto;
}

.image {
  width: 100%;
}

.header {
  color: #254e5f;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 2rem;
  letter-spacing: 0.01em;
}

.text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #333;
}
