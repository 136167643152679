.auth-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

@media (max-width: 991px) {
  .between {
    justify-content: space-between;
  }
}

.menu-item {
  margin-left: 9px;
  color: #ea8242 !important;
  font-size: 1rem !important;
  text-decoration: none;
}

.title {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

.title p {
  margin-bottom: 0;
  line-height: 20px;
}

.title,
.title p,
.title a {
  max-width: 15.875rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 515px) {
  .title,
  .title p,
  .title a {
    max-width: 14.875rem;
  }
}

.title a {
  align-self: end;
  color: #adadb8;
  font-size: 14px;
  line-height: 20px;
  transition: 0.3s;
}

.title a:hover {
  color: #4d4d52;
  transition: 0.3s;
}

.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  background: #fdfdfd;
  border: 1px solid #ea8242;
  border-radius: 50%;
}

.counter {
  position: absolute;
  top: 0;
  right: 0;
  height: 1rem;
  width: 1rem;
  color: #fff;
  font-size: 10px;
  text-align: center;
  background: #8bc34a;
  border-radius: 50%;
}
