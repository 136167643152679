.main-title {
  color: #254e5f;
  font-size: 2.625rem;
  line-height: 2.75rem;
}

.list {
  list-style: none;
}

.btn {
  margin-top: 2rem;
}

.btn a {
  text-decoration: none;
}

.section {
  height: auto;
  min-height: 100vh;
}

.section:first-child {
  min-height: 80vh;
}

.container,
.main {
  min-height: 100vh;
}

.main {
  padding: 5rem 0;
}

.text-block {
  background: #ea8242;
  color: #fff;
  font-size: 1.375rem;
  line-height: 2rem;
}

.separator {
  height: 1rem;
  background: #f8f8f8;
}

.separator-icon {
  position: relative;
}

.separator-icon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 2rem;
  background-image: url("../images/icons/ellipse.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 3.563rem;
  width: 3.563rem;
}

.separator-icon::before {
  position: absolute;
  content: "";
  bottom: -1rem;
  right: -2rem;
  background-image: url("../images/icons/mark.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 4.063rem;
  width: 6.875rem;
}

.sub-title {
  font-size: 1.5rem;
  line-height: 2.375rem;
  color: #254e5f;
}

.item-title {
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #254e5f;
}

.products-card {
  width: calc(100% / 2 - 4rem);
  margin-bottom: 5.143rem;
}

.products-img {
  max-width: 144px;
  height: auto;
  object-fit: cover;
}

.article-wrapper {
  display: flex;
  justify-content: space-between;
}

.wrap {
  max-width: 645px;
  color: #333;
  font-size: 16px;
  line-height: 22px;
}

.paragraph,
.article-list {
  margin-bottom: 16px !important;
}

.article-list {
  padding-left: 23px;
}

.article-item::marker {
  font-size: 10px;
}

.article-icon {
  display: inline-block;
}

.optimal {
  color: #8bc34a;
}

.medium {
  color: #dcae1d;
}

.below {
  color: #ea8242;
}

.critical {
  color: #d12e34;
}

.not-provided {
  display: inline-block;
  width: 20px;
  height: 2px;
  background-color: #d6d6d6;
}

@media (min-width: 768px) {
  .item-icon {
    margin-right: 5rem;
  }

  .btn {
    margin-top: 5rem;
  }
}

@media (max-width: 1200px) {
  .article-icon {
    display: inline-block;
  }
}

@media (max-width: 991px) {
  .article-icon {
    display: none;
  }
}

@media (max-width: 768px) {
  .products-card {
    width: 100%;
  }
}
