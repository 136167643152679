.profile-menu {
  position: absolute;
  top: -1.125rem;
  right: -1.063rem;
  z-index: 10;
  width: 328px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  overflow: hidden;
}

.head {
  padding: 1.063rem 2rem 1.5rem;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
}

.menu-item:hover {
  transition: 0.3s;
  background: #eee;
}

.border-top {
  border-top: 1px solid #eee;
}

.menu-icon {
  margin-right: 16px;
}

.arrow {
  margin-left: auto;
}

.menu-item p {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  margin-bottom: 0;
  margin-right: 0.5rem;
  color: #254e5f;
  line-height: 20px;
}

.counter {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 10px;
  background: #8bc34a;
  border-radius: 50%;
  margin-left: auto;
}

.border {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.active {
  color: #fff;
  background: #ea8242;
}

.active p {
  color: #fff;
}

.active:hover {
  background: #ea8242;
}

.out-profile p {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  color: #254e5f;
  line-height: 20px;
}

/* меню "Сменить организацию" */

.head-organization {
  width: 328px;
  position: absolute;
  top: -1.125rem;
  right: -1.063rem;
  z-index: 10;
  padding: 1.063rem 2rem 1.5rem;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  overflow: hidden;
}

.menu-organization {
  max-width: 500px;
  position: absolute;
  top: 64px;
  right: -1.063rem;
  z-index: 10;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  overflow: hidden;
}

.item {
  max-width: 500px;
}

@media (max-width: 515px) {
  .profile-menu {
    right: -0.75rem;
    width: 100vw;
  }

  .head {
    padding: 1.063rem 1.7rem 1.5rem 2rem;
  }

  .menu-organization {
    right: -0.75rem;
    width: 100vw;
  }

  .head-organization {
    width: 100vw;
  }
}
