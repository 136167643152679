.aside {
  padding-left: 2rem;
  border-left: 1px solid #eee;
}

.total-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #254e5f;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.5rem;
  text-align: right;
  border-top: 1px solid #eee;
}

.total {
  line-height: 1.25rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 1rem;
  color: #adadb8;
}
