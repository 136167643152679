.order-select {
  position: relative;
  height: 3rem;
}

.label {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  z-index: 0;
  color: #254e5f;
  font-size: 0.75rem;
  font-weight: 400;
}

.select {
  left: 0;
  top: 0;
  z-index: 10;
  height: 3rem;
  width: 100%;
  padding: 1.375rem 2rem 0.375rem 1rem;
  color: #254e5f;
  font-size: 0.875rem;
  font-weight: 400;
  white-space: break-spaces;
  background: transparent;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
}

.select-sort {
  /* for Firefox */

  /* for Chrome */
  appearance: none;
}

.select-sort::-ms-expand {
  /* IE */
  display: none;
}

.option {
  color: #254e5f;
  padding-left: 1rem;
}

.option-default {
  color: #c7c7cc;
  padding-left: 1rem;
}

.select:hover {
  transition: 0.3s;
  border-color: #e76e23;
}

.icon-reset,
.icon-arrow {
  position: absolute;
  bottom: 1.1rem;
  right: 1rem;
  display: none;
  background-color: transparent;
  border: none;
}

.icon-reset img,
.icon-arrow img {
  width: 10px;
  height: 10px;
}

.show-arrow {
  display: block;
}

.show-cross {
  display: block;
}
