.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 100;
}

.spinner {
  position: relative;
  display: block;
  height: 60px;
  width: 60px;
  min-width: 100%;
  top: auto;
  right: auto;
}
