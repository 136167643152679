.header {
  padding: 2rem;
  color: #254e5f;
  font-size: 1.375rem;
  line-height: 1.625rem;
  border-bottom: solid 1px #eee;
}

.close {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.text {
  padding-right: 2.5rem;
}
