.main {
  position: relative;
  height: 2px;
  margin-top: 0.5rem;
}

.back,
.main {
  width: 216px;
}

.back,
.progress {
  height: 100%;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
}

.warn {
  background: #dcae1d;
}

.error {
  background: #d12e34;
}

.success {
  background: #8bc34a;
}

.disabled {
  background: #c7c7cc;
}
