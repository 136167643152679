.item {
  border-bottom: 1px solid #eee;
}

.basket-btn {
  border: none;
  background: #fff;
  outline: none;
}

.name {
  display: flex;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 0.875rem;
  font-weight: 700;
  color: #333;
  line-height: 1.25rem;
  margin-bottom: 1.05rem;
}

.number {
  color: #adadb8;
  line-height: 1.25rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 1rem;
  margin-right: 0.3rem;
}

.title {
  font-size: 1.125rem;
  line-height: 2.375rem;
}

.trash {
  width: 1rem;
  height: 1rem;
}

.subtitle {
  margin-left: 1rem;
  line-height: 2rem;
}

.qty {
  color: #adadb8;
  line-height: 1.25rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

.sum {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  color: #333;
}

.disabled {
  border-bottom: 1px solid #842029;
  opacity: 0.5;
}

.count-wrap {
  width: 17.07rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
