.main-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.header {
  max-width: 800px;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 24px;
  font-weight: 700;
  color: #254e5f;
  line-height: 32px;
  background: #eee;
  border: 1px solid #d6d6d6;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 16px 24px;
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 5%);
}
