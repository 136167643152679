p {
  margin-bottom: 0 !important;
}

.wrapper {
  max-width: 82.5rem;
  padding: 0 1rem;
  margin: 0 auto;
  margin-top: 2rem;
}

.title {
  margin-bottom: 2rem;
  color: #254e5f;
}

.sub-list {
  margin-bottom: 1rem;
  list-style-type: disc;
}

.sublist-default {
  padding: 0;
  padding-right: 10rem;
}

.sub-list li:first-child {
  margin-top: 1rem;
}

.subitem-default {
  margin-left: 1.7rem;
  margin-bottom: 4px;
}

.title-sublist {
  padding-top: 1.8rem;
  padding-bottom: 1.6rem;
  color: #254e5f;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  border-top: 1px solid #eee;
}

.text-margin {
  margin-bottom: 1rem !important;
}

.text-padding-right-large {
  padding-right: 10rem;
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.link {
  color: #ea8242;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
}

.text-danger {
  color: #d12e34;
}

.text-wrap {
  word-wrap: break-word;
}

.text-padding-right-small {
  padding-right: 9.5rem;
}

.img {
  width: 100%;
  height: auto;
}

.mobile-tel-margin {
  margin-left: 5.8rem;
}

@media (max-width: 768px) {
  .text-padding-right-large,
  .text-padding-right-small,
  .sublist-default {
    padding-right: 1rem;
  }

  .subitem-default {
    margin-bottom: 0.5rem;
  }

  .title {
    font-size: 17px;
  }

  .base-text {
    font-size: 15px;
  }

  .mobile-tel-margin {
    margin: 0;
  }
}
