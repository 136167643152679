.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  height: 50px;
  width: 200px;
}

.menu {
  height: 50px;
  width: 40%;
}

@media (max-width: 991px) {
  .menu {
    display: none;
  }
}

.user {
  height: 50px;
  width: 200px;
}

.loading {
  background:
    linear-gradient(
      100deg,
      rgb(0 0 0 / 3%) 30%,
      rgba(0 0 0 / 8%) 50%,
      rgba(0 0 0 / 3%) 70%
    );
  background-size: 400%;
  border-radius: 6px;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
