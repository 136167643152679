.main {
  position: relative;
}

.mt {
  margin-top: 1.5rem;
}

.label {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  color: #254e5f;
  font-size: 12px;
  font-weight: 400;
}

.disabled .label {
  color: #c7c7cc;
}

.input {
  width: 100%;
  height: 3rem;
  padding: 1.375rem 3rem 0.375rem 1rem;
  color: #254e5f;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  outline: none;
  transition: 0.3s;
}

.input::placeholder {
  color: #c7c7cc;
}

.disabled .input {
  background: #f8f8f8;
  border: 1px solid #eee;
}

.edo:hover {
  border-color: #e76e23;
  transition: 0.3s;
}

.platforma:hover {
  border-color: #357d81;
  transition: 0.3s;
}

.error {
  position: absolute;
  padding-left: 1rem;
  padding-top: 0.25rem;
  margin-bottom: 0;
  color: #d12e34;
  font-size: 12px;
}

.icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  background: #f8f8f8;
  box-shadow: inset 0 1px 4px rgb(0 0 0 / 5%);
  border-radius: 0 2px 2px 0;
  cursor: pointer;
}

.clear {
  position: absolute;
  bottom: 1.1rem;
  right: 1rem;
}

.clear img {
  width: 10px;
  height: 10px;
}
