.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  flex: 1;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("../../images/icons/main-back.svg");
}
