.detailed-info {
  padding: 1rem 1.5rem;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
}

.block {
  padding-bottom: 1rem;
  margin-top: 1.5rem;
  border-bottom: 1px solid #eee;
}

.col-desc,
.profile-desc {
  color: #333;
  line-height: 1.25rem;
}

.col-name,
.profile-title {
  margin-bottom: 1%;
  color: #254e5f;
  font-size: 0.8rem;
  line-height: 1rem;
}

.track-num {
  color: #254e5f;
  font-weight: 500;
}

.link {
  display: flex;
  align-items: center;
  color: #ea8242;
  cursor: pointer;
}

.link:hover {
  color: #0a58ca;
}

.download-icon {
  margin-left: 0.5rem;
}

.flex-wrap {
  flex-wrap: wrap;
}

.img {
  display: inline-block;
  margin-left: 3%;
}

.order-num {
  margin-left: 3%;
  color: #adadb8;
}

.pay-btn {
  width: 163px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  border-radius: 2px;
  padding: 8px 16px;
  background: #ea8242;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.pay-status-container {
  display: flex;
  align-items: center;
}

.error {
  font: 12px/16px Roboto, "Segoe UI", serif;
  color: #d12e34;
}

.check-wrap {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.refresh-btn {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ea8242;
  text-decoration: underline;
}

.desc {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.total-sum {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.text {
  color: #ea8242;
  margin-right: 1.5rem;
}

.price {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.with-icon {
  display: flex;
  align-items: center;
}

.with-icon img {
  margin-left: 0.5rem;
}

.detailed-qty {
  position: relative;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.prefix {
  font-size: 0.875rem;
  color: #adadb8;
}

.list {
  padding-left: 1rem;
  margin: 0;
  color: #adadb8;
}

.flex {
  display: flex;
}

.load {
  width: 500px;
  display: flex;
  align-items: center;
  column-gap: 32px;
  margin-left: 32px;
}

@media (max-width: 500px) {
  .load {
    width: auto;
    margin-left: 0;
  }
}

.load a {
  display: block;
  margin-right: 0.5rem;
  color: #ea8242;
}

.button {
  display: flex;
  align-items: center;
  padding: 0.313rem 1rem;
  color: #eeaf41;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: #fff;
  border: 1px solid #eeaf41;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  outline: none;
}

.button img {
  margin-right: 0.5rem;
}

.pull-right {
  float: right;
}

.text-gray {
  color: #adadb8;
}

.remain-sum {
  margin-left: 1rem;
  margin-right: 0.5rem;
  color: #d12e34;
  font-size: 1.188rem;
}

.profile-list {
  width: 70%;
  padding: 0;
  list-style: none;
}

.tooltip-copy {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltiptext-copy a {
  color: #ea8242;
}

.tooltiptext-copy a:hover {
  color: #0a58ca;
}

.tooltip-copy .tooltiptext-copy {
  position: absolute;
  right: -15%;
  bottom: 150%;
  z-index: 1;
  width: auto;
  padding: 0.675rem;
  margin: 0 0 0 -75px;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  background-color: #555;
  border-radius: 6px;
  opacity: 0;
  transition: opacity 0.3s;
  visibility: hidden;
}

.tooltip-copy .tooltiptext-copy::after {
  content: "";
  position: absolute;
  top: 100%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent;
}

.tooltip-copy:hover .tooltiptext-copy {
  visibility: visible;
  opacity: 1;
}

.tooltip-text-pickup {
  width: 14rem;
}

.centered {
  align-self: center;
}

.delivery-price {
  color: #254e5f;
}

@media (max-width: 768px) {
  .list {
    list-style: none;
    padding-left: 0;
  }

  .button {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .profile-item {
    width: calc(100% / 3 - 1rem);
  }

  .profile-list {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .media-width {
    width: 33% !important;
  }
}

@media (min-width: 881px) and (max-width: 1024px) {
  .with-icon-media {
    width: 70%;
  }
}

@media (min-width: 768px) and (max-width: 881px) {
  .with-icon-media {
    width: 50%;
  }
}
