.base-placeholder {
  position: relative;
  z-index: 9;
  padding: 0.6rem 0;
  background:
    linear-gradient(
      100deg,
      #f8f8f8 30%,
      #fdfdfd 50%,
      #f8f8f8 70%
    );
  background-size: 400%;
  border-radius: 6px;
  animation: loading 1.2s ease-in-out infinite;
}

.placeholder-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.placeholder-row {
  height: 3.75rem;
  margin-top: 1.5rem;
}

.placeholder-title {
  background:
    linear-gradient(
      100deg,
      #f8f8f8 30%,
      #fdfdfd 50%,
      #f8f8f8 70%
    );
  max-width: 15.625rem;
  height: 2rem;
  background-size: 250%;
}

.paragraph-placeholder {
  width: 50%;
  margin-bottom: 0.5rem;
}

.placeholder-separator {
  margin: 2rem 0;
  background: #eee;
  height: 1px;
}

.placeholder-product-list {
  display: flex;
  flex-flow: row wrap;
  margin-top: 1.714rem;
  justify-content: space-between;
}

.placeholder-product-item {
  width: 100%;
  margin-bottom: 5.143rem;
}

.paragraph-placeholder:nth-child(2) {
  width: 73%;
}

.paragraph-placeholder:nth-child(3) {
  width: 64%;
}

.paragraph-placeholder:last-child {
  width: 60%;
}

.image-placeholder {
  width: 100%;
  height: 100%;
}

.image-big {
  width: 304px;
  height: 324px;
}

.placeholder-product-list .image-medium {
  width: 144px;
  height: 144px;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

.placeholder-product-list .placeholder-row {
  height: 3rem;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

@media (min-width: 768px) {
  .col-row {
    display: flex;
    justify-content: space-between;
  }

  .col-4-row div {
    width: calc(100% / 4 - 0.75rem);
  }

  .col-2-row div {
    width: calc(100% / 2 - 0.75rem);
  }

  .placeholder-product-item {
    position: relative;
    width: calc(100% / 2 - 4rem);
  }

  .image-medium {
    margin-bottom: 0;
  }
}
