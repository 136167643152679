.order-delivery {
  width: 100%;
  color: #254e5f;
}

.pl-2 {
  padding-left: 2rem;
}

.title {
  font-size: 1.125rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 700;
  line-height: 1.5rem;
  color: #254e5f;
}

.block {
  border-top: 1px solid #eee;
  margin-top: 1rem;
}

.label {
  color: #adadb8;
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}

.error {
  padding-top: 0.25rem;
  padding-left: 1rem;
  color: #d12e34;
  font-size: 12px;
  margin-bottom: 0;
}

.textarea {
  width: 100%;
  font-family: Roboto, "Segoe UI", serif;
  font-size: 0.875rem;
  line-height: 1rem;
  height: 5rem;
  outline: none;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  padding: 1.375rem 1rem 0.375rem;
  color: #254e5f;
  transition: 0.3s;
  resize: none;
}

.textarea::placeholder {
  color: #c7c7cc;
}

.items-price {
  color: #adadb8;
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.price-delivery {
  line-height: 1.25rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 1rem;
  color: #adadb8;
}

.price {
  font-size: 1.187rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 700;
  line-height: 2.5rem;
  white-space: nowrap;
  color: #254e5f;
}

.link {
  color: #ea8242;
  display: block;
  margin-right: 0.5rem;
  font-size: 0.875rem; /* 14px */
}

.col-desc {
  line-height: 1.25rem;
  color: #333;
  font-size: 0.875rem;
}

.col-name {
  color: #254e5f;
  line-height: 1rem;
  font-size: 0.75rem;
}

.img-size {
  line-height: 1.5rem;
  width: 1.875rem;
  height: 1.875rem;
  margin-left: auto;
}

.input {
  width: 273px;
  height: 48px;
}

.input::placeholder {
  color: #c7c7cc;
}

.input:hover {
  border-color: #e76e23;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .order-delivery {
    margin-top: 2rem;
    padding-left: 0;
  }
}
