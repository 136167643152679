.switch-tabs {
  background: #f8f8f8;
  border: 3px solid #eee;
  box-shadow: inset 0 1px 4px rgb(0 0 0 / 5%);
  border-radius: 3px;
}

.switch-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.switch-tab:hover {
  transition: 0.3s;
  border-color: #e76e23;
}

.title,
.label {
  font-size: 0.75rem;
  line-height: 1rem;
}

.title {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  color: #254e5f;
  margin-top: 0.125rem;
}

.label {
  width: 100%;
  padding: 0.4rem 0;
  font-family: Roboto, "Segoe UI", serif;
  font-weight: 700;
  line-height: 1rem;
  color: #254e5f;
  background: #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
}

.input:checked + label {
  background: #fff;
  color: #ea8242;
  font-weight: 500;
}

@media (max-width: 768px) {
  .label {
    padding: 0.875rem 0;
  }
}
