.registration {
  position: absolute;
  top: -1.125rem;
  right: -1.063rem;
  z-index: 10;
  width: 22.5rem;
  padding: 1.063rem 2rem 1.5rem;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 0 0 4px 4px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  overflow: hidden;
}

@media (max-width: 515px) {
  .registration {
    right: -0.75rem;
    width: 100vw;
    padding: 1.063rem 1.7rem 1.5rem 2rem;
  }
}

.info {
  font-size: 12px;
  margin-bottom: 30px;
}

.info input {
  cursor: pointer;
}

.info a {
  color: #0074fe;
  text-decoration: underline;
  margin: 0 4px 0 10px;
}

.info a:hover {
  color: #004eb4;
}

.errors {
  color: #ff4e4e;
  font-size: 0.875rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sign-in {
  color: #4ba1a5;
  font-size: 14px;
  text-decoration: underline;
  line-height: 20px;
  cursor: pointer;
}

.captcha {
  margin-top: 30px;
}
