.base-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333;
  letter-spacing: 0.03em;
}

@media (max-width: 768px) {
  .base-text {
    font-size: 15px;
  }
}
