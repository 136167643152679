.product-counter {
  display: flex;
  align-items: center;
  height: 3rem;
}

.btn {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.increment {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
}

.decrement {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}

.btn:disabled {
  opacity: 0.3;
}

.btn img {
  margin: 0 auto;
}

.score input {
  width: 3rem;
  height: 2.5rem;
  text-align: center;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 1rem;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .score input,
  .btn {
    width: 3rem;
    height: 3rem;
  }
}
