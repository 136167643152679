.footer {
  flex: 0;
  padding: 24px 0;
  color: #254e5f;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  background: #f8f8f8;
  box-shadow: inset 0 2px 4px rgb(0 0 0 / 7%);
}

.list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.list li {
  padding-bottom: 12px;
  white-space: nowrap;
  line-height: 1rem;
}

.button {
  color: #254e5f;
}

.button:hover {
  color: #0a58ca;
}

.footer a {
  color: #254e5f;
}

.copy {
  padding-bottom: 12px;
  font-weight: 900;
  line-height: 2rem;
  white-space: nowrap;
}

.contacts a {
  padding-left: 8px;
  text-decoration: none;
}

.contacts a:hover {
  text-decoration: underline;
}

.phone,
.mail {
  white-space: nowrap;
  padding-bottom: 12px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}
