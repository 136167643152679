.loader {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 100%;
  max-width: 1.625rem;
  animation: spinner 1s linear infinite;
}

.loader circle {
  fill: none;
  stroke-width: 8px;
  stroke-dasharray: 300;
}

@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}
