.accept-ready {
  margin: auto;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}

.bottom img {
  display: block;
  margin-right: 8px;
}

.bottom div {
  color: #8bc34a;
  font-size: 14px;
  font-weight: 500;
}
