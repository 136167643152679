.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  border-radius: 2px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
}

.modal-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.modal-col {
  display: flex;
  flex-direction: column;
  width: 24.969rem;
  padding: 0 32px 32px;
}

.content {
  padding: 2rem;
}
