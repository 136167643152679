.list-item {
  position: relative;
  max-width: 752px;
  display: flex;
  justify-content: center;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  padding: 14px 24px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
}

.list-item:hover {
  border: 1px solid #a9a9a9;
}

.in-menu-list-item {
  display: flex;
  justify-content: flex-start;
  border-radius: 2px;
  padding: 10px 16px;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%), 0 1px 1px -1px rgb(0 0 0 / 10%);
}

.in-menu-list-item:hover {
  background: #eee;
}

.gradient {
  position: absolute;
  left: 406px;
  bottom: 0;
  width: 40px;
  height: 38px;
  background: linear-gradient(to right, transparent, #fff 70%);
}

.in-menu-list-item:hover .gradient {
  background: linear-gradient(to right, transparent, #eee 70%);
}

.img {
  position: absolute;
  top: 23px;
  left: 24px;
}

.in-menu-img {
  margin-right: 16px;
}

.list-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.in-menu-list-item-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item-name {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 16px;
  font-weight: 600;
  color: #ea8242;
  line-height: 20px;
  margin-bottom: 2px;
  text-align: center;
}

.in-menu-item-name {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 14px;
  font-weight: 700;
  color: #333;
  line-height: 20px;
  margin-bottom: 2px;
  text-align: center;
}

.list-item-wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
  text-align: center;
  font-family: Roboto, "Segoe UI", serif;
  font-size: 12px;
  line-height: 16px;
  color: #adadb8;
}

.dot {
  width: 4px;
  height: 4px;
  background: #d6d6d6;
  border-radius: 50%;
}

.in-menu-list-item-wrap {
  width: 1000px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-family: Roboto, "Segoe UI", serif;
  font-size: 12px;
  line-height: 16px;
  color: #adadb8;
  overflow: hidden;
}

.in-menu-dot {
  width: 4px;
  height: 4px;
  background: #d6d6d6;
  border-radius: 50%;
}

@media (max-width: 820px) {
  .list-item-wrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dot {
    display: none;
  }

  .img {
    display: none;
  }
}

@media (max-width: 515px) {
  .in-menu-list-item {
    justify-content: center;
    text-align: center;
  }

  .in-menu-list-item-wrap {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .in-menu-list-item-container {
    align-items: center;
  }

  .in-menu-dot {
    display: none;
  }

  .in-menu-img {
    display: none;
  }
}
