.honest {
  position: absolute;
  z-index: 1;
}

.container-min {
  position: relative;
  width: 152px;
  height: 162px;
}

.container-min .honest {
  top: 17px;
  left: -10px;
}

.container-big {
  position: relative;
  width: 304px;
  height: 324px;
}

.container-big .honest {
  top: -13px;
  left: 15px;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.active-slide {
  opacity: 1;
}

.pagination {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
}

.dot {
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.25rem;
  background: #f8f8f8;
  border-radius: 50%;
  box-shadow: inset 0 2px 4px rgb(0 0 0 / 7%);
  cursor: pointer;
}

.active-dot {
  background: #ea8242;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}
