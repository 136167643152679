.form {
  margin-top: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.orders {
  padding: 50px 0;
}

.head,
.form-desc,
.container {
  margin: 0 auto;
}

.main {
  font-size: 0.875rem;
  text-align: left;
  margin-top: 0.5rem;
}

.col-name {
  padding: 1rem 1.5rem;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  margin: 0 -0.75rem;
  color: #adadb8;
}

.col-name > div {
  /* flex: 1; */
  padding: 0 0.75rem;
}

.date {
  /* flex: 2 !important; */
}

.author,
.sum {
  /* flex: 3 !important; */
}

.delivery {
  /* flex: 4 !important; */
}

.no-auth {
  padding: 100px;
  color: #ea8242;
}

.btn-block {
  border-bottom: 1px solid #f8f8f8;
  position: relative;
  margin-top: 4rem;
  display: none;
}

.btn-wrapper {
  width: 20%;
  margin: 0 auto;
  margin-top: 3rem;
}

.button img {
  margin-right: 0.5rem;
}

.reject-status {
  width: 720px;
  border: 1px solid #d12e34;
  background: #faebec;
}

.container-pay-order {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 32px;
  padding: 8px 40px 40px;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.pending-status {
  width: 720px;
  border: 1px solid #dcae1d;
  background: #faf5e5;
}

.return-list-order {
  width: 267px;
  border: 1px solid #ea8242;
  border-radius: 2px;
  padding: 14px 32px;
  color: #ea8242;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 5%),
    0 1px 10px -1px rgb(0 0 0 / 5%);
}

.refresh-btn {
  width: 168px;
  display: flex;
  column-gap: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  padding: 14px 32px;
  color: #ea8242;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 5%),
    0 1px 10px -1px rgb(0 0 0 / 5%);
}

.btn-container-pend {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .modal {
    height: 100%;
    width: 100%;
  }

  .success-status,
  .reject-status {
    width: 88%;
  }

  .pending-status {
    width: 100%;
  }

  .container-pay-order {
    width: 100%;
    padding: 4px 20px 20px;
    row-gap: 16px;
  }

  .refresh-btn {
    padding: 7px 16px;
  }

  .return-list-order {
    padding: 7px 16px;
  }

  .btn-container-pend {
    flex-direction: column;
    row-gap: 16px;
  }

  .wrap-card,
  .wrap-info,
  .wrap-info-card,
  .return-list-order,
  .refresh-btn {
    width: 100%;
  }

  .content-header {
    font-size: 18px;
  }

  .status {
    font-size: 14px;
  }

  .desc {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .form {
    border-bottom: none;
  }
}

@media (max-width: 870px) {
  .success-status,
  .reject-status {
    width: 80%;
  }

  .pending-status {
    width: 100%;
  }

  .container-reject-order {
    width: 100%;
  }

  .container-pay-order {
    width: 100%;
  }

  .wrap-card,
  .wrap-info,
  .wrap-info-card,
  .return-list-order,
  .refresh-btn {
    width: 100%;
  }

  .status {
    font-size: 16px;
  }

  .desc {
    font-size: 12px;
  }

  .btn-container-pend {
    flex-direction: column;
    row-gap: 24px;
  }

  .refresh-btn {
    display: flex;
    justify-content: center;
    max-width: 50%;
  }

  .return-list-order {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .form {
    background: #fdfdfd;
    margin-top: 0;
    padding-top: 3rem;
  }

  .form-desc {
    max-width: 60rem;
    padding: 0 0.75rem;
  }

  .col-name {
    display: none;
  }

  .head {
    max-width: 60rem;
  }

  .container {
    max-width: 60rem;
    padding: 0 1rem;
  }

  .btn-block {
    display: block;
  }

  .btn-wrapper {
    width: 37%;
  }
}

@media (min-width: 1025px) {
  .head,
  .container {
    max-width: 82.5rem;
    padding: 0 1rem;
  }
}

@media (max-width: 767.9px) {
  .select-wrapper {
    margin-top: 1rem;
  }

  .btn-wrapper {
    width: 50%;
  }

  .title {
    padding-left: 1rem;
  }
}
