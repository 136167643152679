table {
  position: relative;
  color: #254e5f;
  font-size: 14px;
}

td {
  position: relative;
  padding: 16px;
}

.separator:nth-child(3n)::after,
.category-separator:nth-child(2n)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  display: inline-block;
  height: 55%;
  width: 1px;
  background-color: #eee;
  transform: translateY(-50%);
}

.category {
  font-weight: 500;
  border-bottom: 1px solid #eee;
}

.title-value {
  width: 108px;
}

.title-value img {
  display: inline-block;
  margin-top: 1px;
  margin-right: 8px;
  vertical-align: top;
}
