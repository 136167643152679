.input-search {
  position: relative;
  z-index: 100;
}

.list {
  width: 100%;
  background-color: white;
  position: absolute;
  top: 50px;
}

.additional-data {
  color: #254e5f;
  font-size: 14px;
  line-height: 1.3;
  margin: 7px 0 0;
  padding: 0 0 0 15px;
}

.errors {
  color: #ff4e4e;
  font-size: 0.875rem;
}
