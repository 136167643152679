.button {
  height: 3rem;
  width: 100%;
  color: #fff;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.edo {
  background: #ea8242;
  border-radius: 2px;
  transition: 0.3s;
}

.edo:hover {
  background: #e76e23;
  border-color: #e76e23;
  transition: 0.3s;
}

.lkfn {
  color: #e25f12;
  background: #fff;
  border: 1px solid #e25f12;
  border-radius: 2px;
  transition: 0.3s;
}

.lkfn:hover {
  color: #fff;
  background: #ea8242;
  border-color: #e76e23;
  transition: 0.3s;
}

.platforma {
  background: #4ba1a5;
  border: 1px solid #357e81;
  border-radius: 2px;
  box-sizing: border-box;
  transition: 0.3s;
}

.platforma:hover {
  background: #357d81;
  border-color: #357d81;
  transition: 0.3s;
}

.link-icon {
  padding-left: 0.5rem;
}

.loading {
  opacity: 0.3;
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

.disabled:hover {
  background: #ea8242;
}

.progress {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: 100%;
  background:
    #df5f11
    linear-gradient(
      45deg,
      rgb(255 255 255 / 15%) 25%,
      transparent 25%,
      transparent 50%,
      rgb(255 255 255 / 15%) 50%,
      rgb(255 255 255 / 15%) 75%,
      transparent 75%,
      transparent
    );
  background-size: 40px 40px;
  transition: width 0.6s ease;
  animation: progress-bar-stripes 2s linear infinite;
  transform: translate(-1px, -1px);
  border-radius: 2px;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 40px 0;
  }
}
