.container-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: auto;
}

.wrap {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  margin-bottom: 8px;
}

.check-create {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #333;
}

.middle-time {
  font-family: Roboto, "Segoe UI", serif;
  font-size: 12px;
  line-height: 16px;
  color: #adadb8;
}

.min {
  color: #333;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}
