.modal {
  height: 100vh;
  width: 100vw;
  background: rgb(0 0 0 / 30%);
  padding: 15px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s;
  z-index: 1000;
}

.modal-active {
  opacity: 1;
  pointer-events: all;
  z-index: 1000;
}

.content {
  position: relative;
  border-radius: 8px;
  background: #fff;
  transform: scale(0.5);
  transition: all 0.2s;
  z-index: 1000;
}

.content-active {
  transform: scale(1);
}

.content-header {
  display: flex;
  justify-content: space-between;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #254e5f;
  padding: 24px 40px;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 5%),
    0 1px 10px -1px rgb(0 0 0 / 5%);
}

.cross {
  cursor: pointer;
}

/* status */

.status {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

/* pay-order status */

@media (max-width: 870px) {
  .success-status,
  .reject-status {
    width: 80%;
  }

  .pending-status {
    width: 100%;
  }

  .container-reject-order {
    width: 100%;
  }

  .container-pay-order {
    width: 100%;
  }

  .wrap-card,
  .wrap-info,
  .wrap-info-card,
  .return-list-order,
  .refresh-btn {
    width: 100%;
  }

  .content-header {
    padding: 16px 24px;
    font-size: 20px;
  }

  .status {
    font-size: 16px;
  }

  .desc {
    font-size: 12px;
  }

  .btn-container-pend {
    flex-direction: column;
    row-gap: 24px;
  }

  .refresh-btn {
    display: flex;
    justify-content: center;
    max-width: 50%;
  }

  .return-list-order {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .modal {
    height: 100%;
    width: 100%;
  }

  .success-status,
  .reject-status {
    width: 88%;
  }

  .pending-status {
    width: 100%;
  }

  .container-pay-order {
    width: 100%;
    padding: 4px 20px 20px;
    row-gap: 16px;
  }

  .refresh-btn {
    padding: 7px 16px;
  }

  .return-list-order {
    padding: 7px 16px;
  }

  .btn-container-pend {
    flex-direction: column;
    row-gap: 16px;
  }

  .wrap-card,
  .wrap-info,
  .wrap-info-card,
  .return-list-order,
  .refresh-btn {
    width: 100%;
  }

  .content-header {
    font-size: 18px;
  }

  .status {
    font-size: 14px;
  }

  .desc {
    font-size: 12px;
  }
}
