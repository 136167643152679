.wrapper {
  margin: 0.5rem 0;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rule {
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  cursor: pointer;
}

.rule a {
  text-decoration: none;
}

.left {
  display: flex;
}

.title {
  padding: 1.5rem;
  color: #254e5f;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
}

.arrow {
  width: 11px;
  height: 11px;
  margin-right: 1.5rem;
  transform: rotate(0deg);
  transition: 0.3s;
}

.active {
  transform: rotate(180deg);
  transition: 0.3s;
}

.sub-list {
  list-style-type: disc;
  margin-bottom: 1rem;
}

.sublist-default {
  padding: 0;
}

.subitem-default {
  margin-left: 32px;
}

.link {
  color: #ea8242;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
}

@media (max-width: 768px) {
  .title {
    font-size: 17px;
  }

  .arrow {
    margin-left: 2rem;
  }
}
