.fn-alert {
  margin-bottom: 0;
}

.fn-alert:last-child {
  margin-bottom: 1rem;
}

.auth {
  color: #0a53be;
  text-decoration: underline;
  cursor: pointer;
}

.auth:hover {
  color: #167eff;
}
