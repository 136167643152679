.main {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text {
  color: #adadb8;
}

.few {
  color: #ea8242;
}

.enough {
  color: #8bc34a;
}

.much {
  color: #8bc34a;
}
