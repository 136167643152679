button {
  background-color: transparent;
  border: none;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
}

.disabled {
  opacity: 0.25;
}

.download-link-long,
.download-link-short {
  display: flex;
  align-items: center;
}

.download-link-long {
  justify-content: space-between;
  width: 100%;
  color: #254e5f;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.download-link-short {
  color: #ea8242;
  cursor: pointer;
}

.download-link-short:hover {
  color: #0a58ca;
}

.download-link-long:hover {
  color: #ea8242;
}

.doc-img {
  margin-right: 1rem;
}

.icon {
  position: absolute;
  top: 0;
  opacity: 1;
  transition: 0.2s linear;
}

.hidden {
  opacity: 0;
}

.download-btn {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ea8242;
  margin: 14px 100px;
}

@media (max-width: 767px) {
  .icon {
    top: 0;
  }
}
