.inner {
  position: relative;
}

:global(.lk-tooltip .tooltip-inner) {
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  background-color: #555;
  transition: opacity 0.3s;
}

:global(.lk-tooltip.bs-tooltip-top .tooltip-arrow::before) {
  border-top-color: #555;
}
