.orders-item {
  position: relative;
  margin: 1.5rem 0;
}

.orders-item:first-child {
  margin: 0.6rem 0 1.5rem;
}

.main {
  padding: 1.5rem;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  border-radius: 4px;
  cursor: pointer;
}

.main.active {
  border-radius: 4px 4px 0 0;
}

.detailed-info {
  padding: 1rem 4.5rem 2rem;
  background: #f8f8f8;
  border: 1px solid #eee;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  border-radius: 4px;
}

.block {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 0.75rem;
}

.qty {
  display: flex;
  align-items: center;
}

.qty img {
  margin-left: 0.25rem;
}

.btn {
  max-width: 0.625rem;
  transform: rotate(0deg);
  transition: 0.3s;
}

.open {
  transform: rotate(180deg);
  transition: 0.3s;
}

.active {
  background: white;
}

.link {
  color: #ea8242;
}

.desc {
  max-width: 26.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.total-sum {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.text {
  color: #ea8242;
  margin-right: 1.5rem;
}

.price {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.with-icon {
  display: flex;
  align-items: center;
}

.icon-status {
  width: 5%;
}

.fn-list {
  cursor: pointer;
}

.sum {
  text-align: right;
}

.icon {
  position: absolute;
  top: 0;
}

.pay-status,
.delivery-status,
.signature-status,
.fn-list {
  display: block;
  width: 32px;
  height: 32px;
}

.delivery-status button,
.pay-status button,
.signature-status button,
.fn-list button {
  display: block;
}

.delivery-status img,
.pay-status img,
.signature-status img,
.fn-list img {
  top: 0;
}

@media (max-width: 1024px) {
  .orders-item {
    margin: 2.5rem 0;
  }
}

@media (max-width: 768px) {
  .main {
    flex-direction: column;
    padding: 1.5rem;
  }

  .top,
  .bottom {
    width: 100%;
  }

  .bottom {
    padding-top: 1rem;
  }

  .border {
    border-top: 1px solid #eee;
  }
}

@media (min-width: 768px) {
  .signing {
    white-space: nowrap;
  }

  .top > div,
  .bottom > div {
    padding: 0 0.75rem;
  }

  .top {
    margin: 0 0 0 -0.75rem;
  }

  .pay-status {
    margin-left: 2.5rem;
  }
}

@media (max-width: 767px) {
  .icon {
    top: 0;
  }
}
