.wrap {
  position: relative;
}

.wrap :global(.react-datepicker-wrapper) {
  width: 100%;
}

.label {
  position: absolute;
  top: 0.5rem;
  left: 2.5rem;
  z-index: 2;
  color: #254e5f;
  font-size: 12px;
  font-weight: 400;
}

.datepicker {
  height: 3rem;
  width: 100%;
  padding: 1.375rem 1rem 0.375rem 2.5rem;
  color: #254e5f;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  outline: none;
}

.datepicker:hover {
  border-color: #e76e23;
  transition: 0.3s;
}

.datepicker::placeholder {
  color: #c7c7cc;
}

.icon {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}

.icon-reset {
  position: absolute;
  bottom: 1.1rem;
  right: 1rem;
  display: none;
  background-color: transparent;
  border: none;
}

.icon-reset img {
  width: 10px;
  height: 10px;
}

.wrap:hover .icon-reset {
  display: block;
}
