.download-doc {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  text-decoration: none;
}

.text {
  color: #254e5f;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.download-doc:hover .text {
  color: #ea8242;
}

.icon-left {
  margin-right: 1rem;
}

.icon-right {
  margin-left: auto;
}
