.content-wrapper {
  max-height: 0;
  padding-left: 32px;
  padding-right: 32px;
  background: #fff;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
  overflow: hidden;
  transition: all 0.3s ease;
}

.show-content {
  max-height: 100vh;
  padding-top: 32px;
  padding-bottom: 32px;
  overflow: auto;
}

.content-wrapper::-webkit-scrollbar {
  width: 2px;
}

.content-wrapper::-webkit-scrollbar-track {
  background-color: #e5e5e5;
}

.content-wrapper::-webkit-scrollbar-thumb {
  background-color: #b1b1b1;
  border-radius: 12px;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
