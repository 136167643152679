.select {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  outline: 1px solid #d6d6d6;
  overflow-y: scroll;
}

.item {
  width: 100%;
  color: #254e5f;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  padding: 3px 0 3px 18px;
  border-bottom: 1px solid #e9e9e9;
}

.inn,
.kpp {
  font-size: 10px;
}

.item:hover {
  background-color: #d6d6d6;
  cursor: pointer;
}
