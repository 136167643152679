.wrap {
  max-width: 800px;
  padding: 24px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
}

.in-menu-wrap {
  max-width: 500px;
  padding: 0 16px 16px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: 1px solid #d6d6d6;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%), 0 1px 1px -1px rgb(0 0 0 / 10%);
}

.container {
  display: flex;
  max-width: 752px;
  padding: 8px 16px;
  border: 1px solid #d6d6d6;
  border-radius: 58px;
  background: #f8f8f8;
  align-items: center;
}

.in-menu-container {
  display: flex;
  max-width: 752px;
  padding: 8px 10px 8px 16px;
  border: 1px solid #d6d6d6;
  border-radius: 58px;
  background: #f8f8f8;
  align-items: center;
}

.search {
  width: 16px;
  margin-right: 8px;
}

.list {
  width: 800px;
  height: 680px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 24px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
}

.height-info {
  height: 600px;
}

.in-menu-list {
  max-height: 469px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
}

@media (max-width: 820px) {
  .list {
    width: 100%;
  }
}
