.main {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  color: #254e5f;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  outline: none;
  transition: 0.3s;
}

.main.disabled {
  background: #f8f8f8;
  border: 1px solid #eee;
}

.label {
  margin-left: 0.5rem;
}

.mt {
  margin-top: 1.5rem;
}
