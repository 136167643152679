.list {
  position: absolute;
  z-index: 98;
  padding: 0;
  border: 1px solid #d6d6d6;
  border-top: 0;
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
  list-style: none;
}

.list li {
  color: #254e5f;
  padding: 0.5rem;
  font-size: 0.875rem;
  cursor: pointer;
}

.active {
  background: rgb(214 214 214 / 20%);
}
