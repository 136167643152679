.wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: fit-content;
}

.top {
  display: flex;
  align-items: center;
  flex: 1;
}

.image {
  margin-right: 5%;
}

.text {
  color: #254e5f;
  line-height: 1rem;
}

.transform-text {
  text-transform: lowercase;
}

.transform-text::first-letter {
  text-transform: uppercase;
}

.disable-text {
  margin-right: 1%;
}
