.block {
  border-top: 1px solid #eee;
}

.items-price {
  color: #adadb8;
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.price {
  font-size: 1.25rem;
  line-height: 2.5rem;
  color: #254e5f;
}
