.container {
  width: 720px;
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  margin: 24px auto 0;
  border: 1px solid #8bc34a;
  border-radius: 4px;
  padding: 16px;
  font-family: "Nunito Sans", "Segoe UI", serif;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 10px -1px rgb(0 0 0 / 10%);
  background: #f5f9f0;
}

.wrap {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.status {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.desc {
  font-size: 14px;
  line-height: 18px;
}

@media (max-width: 870px) {
  .container {
    width: 100%;
  }

  .status {
    font-size: 16px;
  }

  .desc {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100%;
  }

  .status {
    font-size: 14px;
  }

  .desc {
    font-size: 12px;
  }
}
