.order-item {
  width: 100%;
  padding-right: 2rem;
  color: #254e5f;
  border-right: 1px solid #eee;
}

.aside {
  padding-right: 0;
  border-right: none;
}

.title {
  color: #254e5f;
  font-size: 1.125rem;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 700;
  line-height: 2rem;
}

.box-icon {
  margin-left: auto;
  width: 1.375rem;
  height: 1.5rem;
}

.items-price {
  color: #adadb8;
  font-size: 0.875rem;
  line-height: 1.75rem;
}

.price {
  color: #254e5f;
  font-size: 1.25rem;
  line-height: 2.5rem;
}

@media (max-width: 768px) {
  .order-item {
    padding-right: 0;
    margin-top: 2rem;
    border-right: none;
  }
}
