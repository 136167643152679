.return-link {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #adadb8;
}

.tabs {
  padding: 0;
  border-bottom: 1px solid #d6d6d6;
}

.tab {
  text-transform: uppercase;
  list-style: none;
  margin: 0 0.75rem -0.0625rem;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #254e5f;

  &:first-child {
    margin-left: 0;
  }
}

.active-tab {
  color: #ea8242;
  border-bottom-color: #ea8242;
}

.main-desc {
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #333;
}
