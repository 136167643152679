.product-amount {
  display: flex;
  align-items: center;
  height: 3rem;
}

.buy-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.88rem 2.06rem;
  background: #ea8242;
  color: #fff;
  box-shadow:
    0 2px 4px rgb(52 123 126 / 15%),
    inset 0 1px 2px rgb(255 255 255 / 40%);
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.3s;
}

.buy-btn:hover {
  background: #e76e23;
}

.hidden {
  display: none;
}

.mt {
  margin-top: 2rem;
}

.price {
  margin-right: 1rem;
  color: #254e5f;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2rem;
}

@media (max-width: 1199px) {
  .price {
    margin: 0;
  }
}

.quantity {
  color: #adadb8;
  font-size: 0.88rem;
  margin-left: 0.6rem;
}

@media (max-width: 1199px) {
  .product-amount {
    flex-direction: column;
    height: auto;
  }
}

@media (max-width: 768px) {
  .product-amount {
    align-self: end;
    margin-top: 1.5rem;
    font-family: "Nunito Sans", "Segoe UI", serif;
    font-weight: 700;
  }
}
