.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}

.label {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-size: 12px;
  font-weight: 600;
  color: #254e5f;
  line-height: 16px;
}

.input {
  width: 500px;
  border: none;
  font-family: Roboto, "Segoe UI", serif;
  font-size: 14px;
  line-height: 16px;
  color: #254e5f;
  cursor: pointer;
  background: #f8f8f8;
}

.input::placeholder {
  font-family: Roboto, "Segoe UI", serif;
  font-size: 14px;
  line-height: 16px;
  color: #adadb8;
}

.input:focus {
  outline: none;
}

.gradient-input {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 16px;
  background: linear-gradient(to right, transparent, #f8f8f8 100%);
}

@media (max-width: 820px) {
  .input {
    width: 100%;
  }
}
