.container {
  display: flex;
  column-gap: 24px;
  padding: 24px 40px 40px;
}

.card {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 24px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
}

.wrap-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #254e5f;
}

.success-status {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  margin-top: 16px;
  border: 1px solid #8bc34a;
  border-radius: 4px;
  padding: 14px 16px;
  font-family: "Nunito Sans", "Segoe UI", serif;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 10px -1px rgb(0 0 0 / 10%);
  background: #f5f9f0;
}

.success-icon {
  width: 20px;
  height: 20px;
}

.desc {
  font-size: 14px;
  line-height: 18px;
}

.wrap-info {
  width: 100%;
  height: 48px;
  display: flex;
  column-gap: 12px;
  justify-content: flex-start;
  padding: 8px 16px;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #f8f8f8;
}

.wrap-info-card {
  max-width: 300px;
  height: 48px;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  padding: 10px 32px;
  column-gap: 20px;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #f8f8f8;
}

.check-header {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #adadb8;
}

.check {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #333;
}

.email-container {
  position: relative;
}

.email-wrap {
  width: 100%;
  height: 48px;
  display: flex;
  column-gap: 8px;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  padding: 14px 16px;
}

.email-wrap-error {
  border: 1px solid #d12e34;
}

.email {
  width: 100%;
  border: none;
}

.email::placeholder {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #254e5f;
}

.email:focus {
  outline: none;
}

.email-error {
  position: absolute;
  left: 16px;
  bottom: -18px;
  font-family: Roboto, "Segoe UI", serif;
  font-size: 12px;
  line-height: 16px;
  color: #d12e34;
}

.download-btn {
  font-family: "Nunito Sans", "Segoe UI", serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ea8242;
  margin: 14px 100px;
}

.disabled-btn {
  color: #fae2d3;
}

.exclamation {
  width: 16px;
  height: 16px;
}

@media (max-width: 870px) {
  .container {
    flex-direction: column;
    row-gap: 24px;
  }

  .wrap-card,
  .wrap-info,
  .wrap-info-card,
  .download-btn {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 16px 20px 20px;
  }

  .wrap-card {
    font-size: 18px;
  }

  .wrap-info,
  .wrap-info-card {
    padding: 8px 12px;
  }

  .check {
    font-size: 12px;
  }

  .inn {
    font-size: 10px;
  }

  .download-btn {
    font-size: 14px;
  }
}
