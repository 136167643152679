.header {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: #254e5f;
  text-align: left;
}

.separator {
  margin: 2rem 0;
  background: #eee;
  height: 1px;
}

.no-auth {
  padding: 100px;
}
