p {
  margin: 0;
}

.item {
  display: flex;
  align-items: baseline;
}

.marker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-right: 16px;
  color: #333;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ea8242;
  border-radius: 50%;
  box-sizing: border-box;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  width: 82%;
  margin-bottom: 25px;
}
