.row {
  border-bottom: 1px solid #eee;
}

.row:first-child {
  border-top: 1px solid #eee;
}

.row:last-child {
  border: none;
}

.value {
  width: 108px;
}

.value:nth-child(3n)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  display: inline-block;
  height: 55%;
  width: 1px;
  background-color: #eee;
  transform: translateY(-50%);
}
